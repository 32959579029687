import rp from 'request-promise';
import BaseService from './base.service';

export default class BoletoService extends BaseService {

    constructor(state) {
        super(state);
        this.url = `${this.url}boleto/${this.version}`
    }

    getDadosBoleto(token, callback) {
        fetch(`${this.url}?id=${this.id}&access_token=${token}&telefone=${this.id_tel}`)
            .then(res => res.json())
            .then(callback);
    }

    getDadosVencimento(token, vencimento, callback) {
        fetch(`${this.url}?id=${this.id}&access_token=${token}&telefone=${this.id_tel}&vencimento=${vencimento}`)
            .then(res => res.json())
            .then(callback);
    }

    gerarBoleto(token, boleto, callback) {
        fetch(`${this.url}?id=${this.id}&access_token=${token}&telefone=${this.id_tel}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    vencimento: boleto.vencimento,
                    parcela: boleto.numParcela,
                    isSMS: boleto.isSMS,
                    idCarteira: boleto.idCarteira,
                    qtdParcelasAtraso: boleto.qtdParcelasAtraso,
                    cpfCnpj: boleto.cpfCnpj
                }) 
            })
            .then(res => res.json())
            .then(callback);
    }

    reemissaoBoleto(state, token) {
        const options = {
            method: 'PUT',
            url: `${this.url}`,
            qs: {
                id: this.id,
                access_token: token,
                telefone: this.id_tel
            },
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                vencimento: state.vencimento,
                parcela: state.parcela,
                isSMS: state.isSMS
            },
            json: true
        };

        return rp(options);
    }

}