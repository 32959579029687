import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import HistoricoService from '../../services/historico'

import './pesquisaSatisfacao.css';
import Close from '../../imgs/close.svg';
import ReactGA from 'react-ga';

const PesquisaSatisfacao = props => {
	const [rate, setRate] = useState(0);
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const usuario = useSelector(state => state.usuarioReducer)
	const boleto = useSelector(state => state.boletoReducer)
	const estado = useSelector(state => state.estadoReducer)
	const historicoService = new HistoricoService(estado)


	const handleStarClick = (num) => {
		setRate(num);
		setButtonEnabled(true);
	}
	
	const sendRate = () => {
		ReactGA.event({
			category: 'Click',
			action: 'Confirmar pesquisa'
		});
		if (rate === 1) {
			ReactGA.event({
				category: 'Click',
				action: "Pesquisa muito difícil"
			});
		}
		if (rate === 2) {
			ReactGA.event({
				category: 'Click',
				action: "Pesquisa difícil"
			});
		}
		if (rate === 3) {
			ReactGA.event({
				category: 'Click',
				action: "Pesquisa neutro"
			});
		}
		if (rate === 4) {
			ReactGA.event({
				category: 'Click',
				action: "Pesquisa fácil"
			});
		}
		if (rate === 5) {
			ReactGA.event({
				category: 'Click',
				action: "Pesquisa muito fácil"
			});
		}
		historicoService.saveAvaliacao(estado.token, usuario, boleto, rate)
		props.Close();
	}

	const fechar = () => {
		ReactGA.event({
			category: 'Click',
			action: 'Fechar pesquisa'
		});
		props.Close()
	}

	return (
		<div className='container-pesquisa-satisfacao'>
			<div className='div-pesquisa'>
				<div className='div-close'>
					<img className='close' src={Close} onClick={() => fechar()} alt='close' />
				</div>
				<div className="div-pesquisa-text">
					<span>
						Como foi sua experiência com o boleto digital?
					</span>
					<span>
						Selecione as estrelas para dar a sua opinião:
					</span>
				</div>
				<div>
					<div className='rate'>
						<input type="radio" id="star5" name="rate" value="5" /><label htmlFor='star5' title='5 estrelas' onClick={() => handleStarClick(5)}>5 stars</label>
						<input type="radio" id="star4" name="rate" value="4" /><label htmlFor='star4' title='4 estrelas' onClick={() => handleStarClick(4)}>4 stars</label>
						<input type="radio" id="star3" name="rate" value="3" /><label htmlFor='star3' title='3 estrelas' onClick={() => handleStarClick(3)}>3 stars</label>
						<input type="radio" id="star2" name="rate" value="2" /><label htmlFor='star2' title='2 estrelas' onClick={() => handleStarClick(2)}>2 stars</label>
						<input type="radio" id="star1" name="rate" value="1" /><label htmlFor='star1' title='1 estrela' onClick={() => handleStarClick(1)}>1 stars</label>
					</div>
				</div>
				<div className="div-pesquisa-button">
					<button disabled={!buttonEnabled} onClick={sendRate}>CONFIRMAR</button>
				</div>
			</div>
		</div>
	);
};

export default PesquisaSatisfacao;