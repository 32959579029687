import React from 'react';

import {  } from "module";

import './loading.css'

export default function loading() {
  return (
    <div className='lds-container'>
        <div className='lds-ellipsis'>
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
  );
}
