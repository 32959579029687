import { createStore, combineReducers } from 'redux'
import Boleto from './boleto'
import Usuario from './usuario'
import Estado from './estado'


const reducers = combineReducers({
    boletoReducer: Boleto,
    usuarioReducer: Usuario,
    estadoReducer: Estado
})

const store = createStore(reducers)

export default store