import React from 'react';
import Flex from '../../imgs/flex.png';
import Success from '../../imgs/success.png';

import './telaDesconhece.css';

export default function TelaDesconhece() {
	return (
		<div className='container-tela-desconhece'>
			<img className='tela-desconhece-logo' src={Flex} alt='flex' />
      <div className='tela-desconhece-success'>
        <img src={Success} alt='success' />
      </div>
			<div className='tela-desconhece-items'>
				<div className='tela-desconhece-text'>Obrigado pela informação!</div>
			</div>
		</div>
	);
}
