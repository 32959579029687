import React from 'react';

import './card-parcela.css';

const CardParcela = props => {
    
    const filterCard = (p) => {
        if (p.parcela && (p.valor || p.vencimento)) {
            return (
                <div key={p.parcela} className='card-detalhe-parcela'>
                <div className='card-detalhe-parcela-numero'>
                    <span>Nº parcela: </span>
                    <span>{p.parcela}</span>
                </div>
                <div className='wrap-valor-detalhe-parcela'>
                    {
                        p.valor ? 
                        <div>
                            <span>Valor: </span>
                            {p.valor}
                        </div>
                        :
                        null
                    }
                    {
                        p.vencimento ? 
                        <div>
                            <span>Venc.: </span>
                            {p.vencimento}
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            )
        } else {
            return null
        }
    }
    
    return (
    <div>
        {props.detalhes.parcelas.map(p => (
            filterCard(p)
        ))}
    </div>
    )
}

export default CardParcela;