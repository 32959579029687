import BaseService from './base.service';
import axios from 'axios';

export default class HistoricoService extends BaseService {
	constructor(state) {
		super(state);
		this.urlHistorico = `${this.url}historico/${this.version}`;
	}

	informaDesconhece(callback) {
		fetch(`${this.urlHistorico}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			method: 'POST',
			body: JSON.stringify({ id: this.id, telefone: this.id_tel, id_tipo: 'DESCONHECE' })
		})
			.then(res => res.json())
			.then(callback);
	}

	saveAvaliacao = async (token, usuario, boleto, rate) => {
		const body = {
			idCarteira: boleto.idCarteira,
			beneficiario: boleto.beneficiario,
			nome: usuario.nomecompleto,
			rate: rate,
			idCampanha: this.id,
			numContrato: boleto.NContrato,
			valorTotal: boleto.debitoCorr,
			cpfCnpj: boleto.cpfCnpj,
			telefone: usuario.id_tel
		};

		const url = `${this.url}avaliacao`;

		const config = {
			url,
			data: body,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
            method: 'post',
            params: {
                access_token: token,
                id: this.id
            }
		};

        const result = await axios(config);
        console.debug(result);
	};
}