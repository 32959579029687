import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import swal from 'sweetalert2';
import ReactGA from 'react-ga';

import Barra from '../../imgs/barra.svg';
import Impressora from '../../imgs/impressora.png';
import Sifrao from '../../imgs/sifrao.svg';
import DetalhesDivida from '../../components/detalhesDivida/DetalhesDivida';
import Sky from '../../imgs/sky.svg'

import BoletoActions from '../../actions/boletoActions';

import './telaInicial.css';

export default function TelaInicial() {
	const boleto = useSelector(state => state.boletoReducer);
	const estado = useSelector(state => state.estadoReducer);
	const [inchecked, setInchecked] = useState(false);
	const [openDetalhes, setOpenDetalhes] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const dispatch = useDispatch();
	const boletoActions = new BoletoActions(dispatch, estado, boleto);

	useEffect(() => {
		ReactGA.event({
			category: 'Acesso',
			action: `ACESSO BOLETO`
		});
		if (boleto.textParcela) {
			swal.fire({
				type: 'warning',
				title: 'Parcelas',
				text: boleto.textParcela,
				confirmButtonColor: '#DA2129'
			});
		}
		dispatch({ type: 'SET_LOADING_PAGE', payload: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [boleto]);

	const check = () => {
		ReactGA.event({
			category: 'Click',
			action: 'Termos e condições'
		});
		setInchecked(!inchecked);
		if (inchecked === false) {
			document.getElementById('gerarBoleto').style.backgroundColor = '#da2129';
			setEnabled(true);
		} else if (inchecked === true) {
			setEnabled(false);
			document.getElementById('gerarBoleto').style.backgroundColor = '#737372';
		}
	};

	const handleVencimentoChange = event => {
		boletoActions.getDadosVencimento(event.target.value);
	};

	const handleParcelaChange = event => {
		const parcelaInt = parseInt(event.target.value);
		const parcela = _.chain(boleto.parcelas)
			.filter(p => p.numero === parcelaInt)
			.head()
			.value();
		if (parcela) {
			dispatch({ type: 'SET_PARCELA', payload: parcela });
		}
	};

	const gerarBoleto = () => {
		ReactGA.event({
			category: 'Click',
			action: 'Gerar Boleto'
		});
		if (!!boleto.mensagem) {
			swal.fire({
				type: 'warning',
				title: 'Atenção',
				text: boleto.mensagem,
				confirmButtonColor: '#DA2129'
			}).then(() => {
				dispatch({ type: 'SET_TELA_INICIAL', payload: false });
				boletoActions.emitirBoleto();
			});
		} else {
			dispatch({ type: 'SET_TELA_INICIAL', payload: false });
			boletoActions.emitirBoleto();
		}
	};

	const copiarLinha = () => {
		ReactGA.event({
			category: 'Click',
			action: 'Copiar linha digitável'
		});
	}

	return (
		<div className='Container-inicial'>
			<h1 className='Cabecalho-white'>Não espere, pague seu boleto agora!</h1>

			<div className='wholePaper' id='telaInicial'>
				<div className='PapelTop'>
					<img alt='impressora' src={Impressora} className='impressora' />
					<div className='tela-inicial-text-boleto'>
						<img src={Sky} alt="Sky"/>
					</div>
					<div className='LinhaUm'>
						<div className='tela-inicial-boleto-esquerda'>
							<div className='Title2'>
								<br />À VISTA
								<br />
							</div>
							<p className='subTitleSpace2'>{boleto.aVista}</p>
							<div className='Title'>Nome</div>
							<div className='Texto'>{boleto.nome}</div>
							<div className='TitleSpace'>Nº Contrato</div>
							<div className='Texto'>{boleto.NContrato}</div>
						</div>
						<div className='tela-inicial-sifrao'>
							<img alt='Sifrao' src={Sifrao} />
						</div>
						<div className='tela-inicial-boleto-direita'>
							<div className='Title1'>
								{1 === boleto.numParcela ? 'DÉBITO' : 'TOTAL DAS'}
								<br />
								{1 === boleto.numParcela ? 'CORRIGIDO' : 'PARCELAS'}
							</div>
							<p className='subTitleSpace'>{boleto.debitoCorr}</p>
							<div className='Title'>
								Beneficiário<div className='Texto'>{boleto.beneficiario}</div>
							</div>
							<div className='TitleSpace'>
								Vencimento<div className='Texto'>{boleto.vencimento}</div>
							</div>
						</div>
					</div>
					<div className='tela-inicial-selects'>
						<div className='tela-inicial-parcelas'>
							<div className='Title3'>Nº de parcelas:</div>
							<select value={boleto.parcela} onChange={handleParcelaChange} className='select-parcela'>
								{boleto.parcelas.map(i => (
									<option key={i.numero} value={i.numero}>
										{i.text}
									</option>
								))}
							</select>
						</div>
						<div className='tela-inicial-vencimentos'>
							<div className='Title3'>Vencimentos:</div>
							<select
								value={boleto.vencimento}
								onChange={handleVencimentoChange}
								className='select-vencimento'>
								{boleto.vencimentos.map(i => (
									<option className='select-vencimento-option' key={i.value} value={i.value}>
										{i.text}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
				{boleto.isSMS ? (
					<div className='labelSMS'>Gere o boleto para receber a linha digitável por SMS:</div>
				) : (
					<div className='PapelBottom'>
						<div className='Linha'>
							<img className='Boleto' alt='boleto' src={Barra} />
							<div className='itensBottom'>
								<div className='TitleBottom2'>Linha Digitável</div>
								<p className='textoBottom'>XXXXX.XXXXX XXXXX.XXXXXX XXXXX.XXXXXX X XXXXXXXXXXXXXX</p>
								<button className='Button2' onClick={() => copiarLinha()}>Copiar linha digitável</button>
							</div>
						</div>
					</div>
				)}
				<div className='checkbox'>
					<input type='checkbox' checked={inchecked} id='horns' name='horns' onChange={check} />
					<label htmlFor='horns' style={{ whiteSpace: 'nowrap' }}>
						Li e concordo com o boleto
					</label>
				</div>
				<button className='botao-gerar-boleto' disabled={!enabled} id='gerarBoleto' onClick={gerarBoleto}>
					Gerar Boleto
				</button>
				{boleto.detalhes ? (
					<button className='Button-detalhes' id='gerarBoleto' onClick={() => setOpenDetalhes(!openDetalhes)}>
						Detalhes do acordo
					</button>
				) : null}
			</div>

			{openDetalhes ? (
				<DetalhesDivida detalhes={boleto.detalhes} onClose={() => setOpenDetalhes(!openDetalhes)} />
			) : null}
		</div>
	);
}
