    
import config from '../config/config';

export default class BaseService {

    constructor(state) {
        this.id = state.id;
        this.version = state.version || '';
        this.id_tel = state.id_tel || '';

        // caso nao tenha a versão, a url ficaria /id/telefone
        // esse if tira o telefone da versão e coloca ele na variavel correta
        if (state.version && (!state.version.startsWith('v') && !state.version.startsWith('t'))) {
            this.version = 'v0';
            this.id_tel = state.version;
        }

        if (process.env.REACT_APP_ENV) {
            this.url = config[`url_${process.env.REACT_APP_ENV}`];
        } else {
            this.url = 'development' === process.env.NODE_ENV ? config.url_dev : config.url_prod;
        }

    }

}